import { ProjectStatus } from '@pidk/api/src/types/api'
import * as Sentry from '@sentry/nextjs'
import type { GetStaticPaths, GetStaticPathsResult, GetStaticProps } from 'next'
import { useRouter } from 'next/router'

import { getProjectBySlug } from '@/api/projects'
import Project from '@/views/Project'
import type { IProjectProps } from '@/views/Project/Project'

const ProjectWithFallback = ({ project }) => {
  const router = useRouter()

  if (router.isFallback) {
    return (
      <div>...</div>
    )
  }

  return (
    <Project
      isPreview={false}
      project={project}
    />
  )
}

export const getStaticPaths: GetStaticPaths = async (): Promise<GetStaticPathsResult> => {
  return {
    paths: [],
    fallback: 'blocking'
  }
}

export const getStaticProps: GetStaticProps = async (context) => {
  const slug = context.params.slug as string

  try {
    const project = await getProjectBySlug(slug as string)

    if (!project || project.status !== ProjectStatus.PUBLISHED) {
      return {
        notFound: true
      }
    }

    const projectProps: IProjectProps = {
      isPreview: false,
      project
    }

    // TODO: add title, seo data
    return {
      props: {
        // TODO: hacky solution, but neccessary, @see https://github.com/vercel/next.js/discussions/11209
        ...JSON.parse(JSON.stringify(projectProps))
      }
    }
  } catch (e) {
    if (e?.statusCode !== 404) {
      Sentry.captureException(e)
    }

    return {
      notFound: true
    }
  }
}

export default ProjectWithFallback
